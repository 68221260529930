import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import {
  addAdditionalUnauthorizedErrorHandler,
  addCaptchaHandler,
  addSessionTokenRefreshHandler,
} from "api/utils/enhancedFetch";
import { getEnvironmentName, getHooks } from "environment";
import { TRANSACTION_STATUS_KEY } from "src/core/analytics/constants";
import { appealSliceReducer as appeal } from "src/features/appeal/AppealPage/exports/state";
import { clearBannerShownEventAfterRegistrationMiddleware } from "src/features/banners/state/clearBannerShownEventAfterRegistrationMiddleware";
import {
  broadcastExternalPersistConfig,
  broadcastExternalReducer,
} from "src/features/broadcastExternal/exports/state";
import {
  addChatGiftToPlayMiddleware,
  chatGiftAnimationReducer as chatGiftAnimation,
} from "src/features/chat/giftAnimation/export";
import messageRequest from "src/features/chat/messageRequest/state/reducer";
import { unlockListenerMiddleware } from "src/features/chat/premiumMessage/middlewares/unlockListenerMiddleware";
import { premiumMessageReducer as premiumMessage } from "src/features/chat/premiumMessage/state/slice";
import { homePageStreams } from "src/features/homePage/shared/streamsState/streamsSlice";
import {
  homePageCreatorsReducer as homePageCreators,
  homePageGamesReducer as homePageGames,
  homePageProfilesReducer as homePageProfiles,
} from "src/features/homePage/v2/exports/state";
import { homePageV2Nearby } from "src/features/homePage/v2/state/nearbySlice";
import {
  marketingReducer as marketing,
  persistConfig as marketingPersistConfig,
} from "src/features/marketing/exports/state";
import { mosV2Reducer as mosV2 } from "src/features/mos/exports/state";
import { welcomePremiumOfferReducer as welcomePremiumOffer } from "src/features/offers/premiumEntrance/welcomePremiumOffer/exports/state";
import { buyEntranceOnBalanceUpdate } from "src/features/offers/premiumEntrance/welcomePremiumOffer/middlewares/buyEntranceOnBalanceUpdate";
import {
  phoneNumberSettingsReducer as phoneNumberSettings,
  persistConfig as phoneNumberSettingsPersistConfig,
} from "src/features/profile/settings/state/phoneNumberSettingsSlice";
import profileSettingsSlice from "src/features/profile/settings/state/profileSettingsSlice";
import bottomSheet from "src/features/promotions/promoBottomSheet/state/bottomSheetSlice";
import { recommendationsEventsMiddleware } from "src/features/recommendations/middlewares/recommendationsEventsMiddleware";
import { recommendationsSetupMiddleware } from "src/features/recommendations/middlewares/recommendationsSetupMiddleware";
import {
  loginPersistConfig,
  loginReducer,
} from "src/features/signin/exports/state/reducer";
import {
  socialGamesGameReducer as socialGamesGame,
  socialGamesInStreamLobbyReducer as socialGamesInStreamLobby,
  socialGamesLobbyReducer as socialGamesLobby,
} from "src/features/socialGames/exports/state";
import {
  tutorialsPersistConfig,
  tutorialsReducer,
} from "src/features/tutorials/shared/exports/state";
import { openWelcomeOnboardingOfferAfterRegistrationMiddleware } from "src/features/welcomeOnboardingOffer/exports";
import welcomeOnboardingOffer from "src/features/welcomeOnboardingOffer/state/welcomeOfferSlice";
import { sendGiftQueue } from "src/state/flows/sendGift";
import { bonusWheelReducer as bonusWheel } from "src/state/tree/bonusWheel";
import giftsCache, {
  persistConfig as giftsCachePersistConfig,
} from "src/state/tree/giftsCache";
import { pipsConfigReducer as pipsConfig } from "src/state/tree/pipsConfig";
import { clearPersistLandingPageKey } from "src/utils/clearPersistLandingPageKey";
import createCaptchaHandler from "src/utils/createCaptchaHandler";
import createSessionTokenRefreshHandler from "src/utils/createSessionTokenRefreshHandler";
import createUnauthorizedErrorHandler from "src/utils/createUnauthorizedErrorHandler";
import injectJoshGoBack from "state/injectJoshGoBack";
import accountIdChangeObserver from "state/middleware/accountIdChangeObserver";
import acmeObserver from "state/middleware/acmeObserver";
import analyticsListener from "state/middleware/analyticsListener";
import { BIAccountIdMiddleware } from "state/middleware/biUserIDMiddleware";
import { financeServiceAcmeMiddleware } from "state/middleware/financeServiceAcmeMiddleware";
import openCashierAfterAutoLoginFailure from "state/middleware/openCashierAfterAutoLoginFailure";
import pendingActionsListener from "state/middleware/pendingActionsListener";
import afterPurchaseTaskManager from "state/tree/afterPurchaseTaskManager";
import autoLogin from "state/tree/autoLogin";
import bannerConfigs from "state/tree/bannerConfigs";
import blockedUsers from "state/tree/blockedUsers";
import bottomScreen from "state/tree/bottomScreen";
import broadcast, { broadcastPersistConfig } from "state/tree/broadcast";
import captcha, {
  persistConfig as captchaPersistConfig,
} from "state/tree/captcha";
import chat from "state/tree/chat";
import configuration, {
  persistConfig as configurationPersistConfig,
} from "state/tree/configuration";
import connectionManager, {
  persistConfig as cmPersistConfig,
} from "state/tree/connectionManager";
import countries, {
  persistConfig as countriesPersistConfig,
} from "state/tree/countries";
import countryByIp from "state/tree/countryByIp";
import creatorApp, {
  persistConfig as creatorAppConfig,
} from "state/tree/creatorApp";
import creditCards, {
  persistConfig as creditCardsPersistConfig,
} from "state/tree/creditCards";
import currencies from "state/tree/currencies";
import customDrawer, {
  persistConfig as customDrawerPersistConfig,
} from "state/tree/customDrawer";
import deviceInfo, {
  persistConfig as deviceInfoPersistConfig,
} from "state/tree/deviceInfo";
import dropdownMenu from "state/tree/dropdownMenu";
import followingList, {
  persistConfig as followingListPersistConfig,
} from "state/tree/followingList";
import giftingRequests from "state/tree/giftingRequests";
import guardian from "state/tree/guardian";
import instagramCache from "state/tree/instagramCache";
import intercom from "state/tree/intercom";
import landingPagePhoenix, {
  persistConfig as landingPagePhoenixConfigPersistConfig,
} from "state/tree/landingPagePhoenix";
import leaderBoardLanding from "state/tree/leaderBoardLanding";
import liveStreamsFeed, {
  persistConfig as liveStreamsFeedPersistConfig,
} from "state/tree/liveStreamsFeed";
import modal from "state/tree/modal";
import navigation from "state/tree/navigation";
import nearbyCountries, {
  persistConfig as nearbyCountriesPersistConfig,
} from "state/tree/nearbyCountries";
import networkAvailability from "state/tree/networkAvailability";
import nftAuction from "state/tree/nft";
import notifications from "state/tree/notifications";
import personalOffers, {
  persistConfig as personalOffersConfig,
} from "state/tree/personalOffers";
import postsFeed from "state/tree/postsFeed";
import profilesCache, {
  persistConfig as profilesCachePersistConfig,
} from "state/tree/profilesCache";
import receivedAcmeIds, {
  persistConfig as receivedAcmeIdsPersistConfig,
} from "state/tree/receivedAcmeIds";
import referralRegistrations from "state/tree/referralRegistrations";
import refreshSessionToken from "state/tree/refreshSessionToken";
import sessionDetails, {
  persistConfig as sessionDetailsPersistConfig,
} from "state/tree/sessionDetails";
import settings, {
  persistConfig as settingsPersistConfig,
} from "state/tree/settings";
import {
  mintroutePersistConfig,
  mintrouteShopReducer,
  promoShopReducer,
  persistConfig as shopPersistConfig,
  shopReducer,
  vipShopReducer,
} from "state/tree/shop";
import socialNetworkTokens, {
  persistConfig as socialNetworkTokensPersistConfig,
} from "state/tree/socialNetworkTokens";
import specialOffers, {
  persistConfig as specialOffersPersistConfig,
} from "state/tree/specialOffers";
import stories from "state/tree/stories";
import streamsCache from "state/tree/streamsCache";
import subscriptionPerStreamerList from "state/tree/subscriptionPerStreamerList";
import subscriptionsPerStreamer from "state/tree/subscriptionsPerStreamer";
import suspensionStatus, {
  persistConfig as suspensionStatusPersistConfig,
} from "state/tree/suspensionStatus";
import tabsConfig, {
  persistConfig as tabsConfigPersistConfig,
} from "state/tree/tabsConfig";
import taskManager from "state/tree/taskManager";
import taxes from "state/tree/taxes";
import tcnn from "state/tree/tcnn";
import toast from "state/tree/toast";
import topBroadcasters from "state/tree/topBroadcasters";
import tournamentsLanding from "state/tree/tournamentsLanding";
import transactionStatus, {
  persistConfig as transactionStatusPersistConfig,
} from "state/tree/transactionStatusAnalytics";
import upgradedStreams from "state/tree/upgradedStreams";
import user, { persistConfig as userPersistConfig } from "state/tree/user";
import userPosts from "state/tree/userPosts";
import userSearch from "state/tree/userSearch";
import validation from "state/tree/validation";
import viewerSession from "state/tree/viewerSession";
import vipConfigs, { vipConfigsPersistConfig } from "state/tree/vipConfigs";
import visitor, {
  persistConfig as visitorPersistConfig,
} from "state/tree/visitor";

const makePersistConfig = (key, baseConfig = {}) => ({
  ...baseConfig,
  key: `${getEnvironmentName()}:${key}`,
  storage,
});

export const history = createBrowserHistory();

const combineAndReorderReducers = (hash) =>
  combineReducers(Object.fromEntries(Object.entries(hash).sort()));

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export const createAppStore = (
  state = undefined,
  middleware = [
    thunk,
    routerMiddleware(history),
    analyticsListener,
    acmeObserver,
    accountIdChangeObserver,
    pendingActionsListener,
    openCashierAfterAutoLoginFailure,
    openWelcomeOnboardingOfferAfterRegistrationMiddleware.middleware,
    clearBannerShownEventAfterRegistrationMiddleware.middleware,
    recommendationsSetupMiddleware,
    financeServiceAcmeMiddleware,
    recommendationsEventsMiddleware,
    unlockListenerMiddleware,
    addChatGiftToPlayMiddleware.middleware,
    sendGiftQueue.listenerMiddleware,
    buyEntranceOnBalanceUpdate,
    BIAccountIdMiddleware,
  ]
) =>
  createStore(
    combineAndReorderReducers({
      router: connectRouter(history),
      login: persistReducer(
        makePersistConfig("login", loginPersistConfig),
        loginReducer
      ),
      user: persistReducer(makePersistConfig("user", userPersistConfig), user),
      connectionManager: persistReducer(
        makePersistConfig("connectionManager", cmPersistConfig),
        connectionManager
      ),
      followingList: persistReducer(
        makePersistConfig("followingList", followingListPersistConfig),
        followingList
      ),
      settings: persistReducer(
        makePersistConfig("settings", settingsPersistConfig),
        settings
      ),
      blockedUsers,
      configuration: persistReducer(
        makePersistConfig("configuration", configurationPersistConfig),
        configuration
      ),
      referralRegistrations,
      deviceInfo: persistReducer(
        makePersistConfig("deviceInfo", deviceInfoPersistConfig),
        deviceInfo
      ),
      broadcast: persistReducer(
        makePersistConfig("broadcast", broadcastPersistConfig),
        broadcast
      ),
      landingPagePhoenix: persistReducer(
        makePersistConfig(
          "landingPagePhoenix",
          landingPagePhoenixConfigPersistConfig
        ),
        landingPagePhoenix
      ),
      personalOffers: persistReducer(
        makePersistConfig("personalOffers", personalOffersConfig),
        personalOffers
      ),
      giftsCache: persistReducer(
        makePersistConfig("gifts", giftsCachePersistConfig),
        giftsCache
      ),
      customDrawer: persistReducer(
        makePersistConfig("customDrawer", customDrawerPersistConfig),
        customDrawer
      ),
      nearbyCountries: persistReducer(
        makePersistConfig("nearbyCountries", nearbyCountriesPersistConfig),
        nearbyCountries
      ),
      profilesCache: persistReducer(
        makePersistConfig("profilesCache", profilesCachePersistConfig),
        profilesCache
      ),
      vipConfigs: persistReducer(
        makePersistConfig("vipConfigs", vipConfigsPersistConfig),
        vipConfigs
      ),
      shop: persistReducer(
        makePersistConfig("shop", shopPersistConfig),
        shopReducer
      ),
      promoShop: promoShopReducer,
      vipShop: persistReducer(
        makePersistConfig("vipShop", shopPersistConfig),
        vipShopReducer
      ),
      mintrouteShop: persistReducer(
        makePersistConfig("mintrouteShop", mintroutePersistConfig),
        mintrouteShopReducer
      ),
      giftingRequests,
      modal,
      subscriptionsPerStreamer,
      afterPurchaseTaskManager,
      bottomScreen,
      subscriptionPerStreamerList,
      viewerSession,
      topBroadcasters,
      currencies,
      liveStreamsFeed: persistReducer(
        makePersistConfig("liveStreamsFeed", liveStreamsFeedPersistConfig),
        liveStreamsFeed
      ),
      notifications,
      postsFeed,
      networkAvailability,
      streamsCache,
      upgradedStreams,
      navigation,
      userSearch,
      userPosts,
      nftAuction,
      tcnn,
      bottomSheet,
      bannerConfigs,
      autoLogin,
      validation,
      taxes,
      profileSettings: profileSettingsSlice,
      guardian,
      socialNetworkTokens: persistReducer(
        makePersistConfig(
          "socialNetworkTokens",
          socialNetworkTokensPersistConfig
        ),
        socialNetworkTokens
      ),
      tabsConfig: persistReducer(
        makePersistConfig("tabsConfig", tabsConfigPersistConfig),
        tabsConfig
      ),
      instagramCache,
      taskManager,
      specialOffers: persistReducer(
        makePersistConfig("specialOffers", specialOffersPersistConfig),
        specialOffers
      ),
      transactionStatus: persistReducer(
        makePersistConfig(
          TRANSACTION_STATUS_KEY,
          transactionStatusPersistConfig
        ),
        transactionStatus
      ),
      captcha: persistReducer(
        makePersistConfig("captcha", captchaPersistConfig),
        captcha
      ),
      intercom,
      leaderBoardLanding,
      tournamentsLanding,
      creditCards: persistReducer(
        makePersistConfig("creditCards", creditCardsPersistConfig),
        creditCards
      ),
      creatorApp: persistReducer(
        makePersistConfig("creatorApp", creatorAppConfig),
        creatorApp
      ),
      countries: persistReducer(
        makePersistConfig("countries", countriesPersistConfig),
        countries
      ),
      countryByIp,
      chat,
      messageRequest,
      chatGiftAnimation,
      premiumMessage: persistReducer(
        makePersistConfig("premiumMessage"),
        premiumMessage
      ),
      welcomePremiumOffer,
      marketing: persistReducer(
        makePersistConfig("marketing", marketingPersistConfig),
        marketing
      ),
      toast,
      dropdownMenu,
      stories,
      suspensionStatus: persistReducer(
        makePersistConfig("suspensionStatus", suspensionStatusPersistConfig),
        suspensionStatus
      ),
      receivedAcmeIds: persistReducer(
        makePersistConfig("receivedAcmeIds", receivedAcmeIdsPersistConfig),
        receivedAcmeIds
      ),
      refreshSessionToken,
      sessionDetails: persistReducer(
        makePersistConfig("sessionDetails", sessionDetailsPersistConfig),
        sessionDetails
      ),
      visitor: persistReducer(
        makePersistConfig("visitor", visitorPersistConfig),
        visitor
      ),
      socialGamesLobby,
      socialGamesGame,
      socialGamesInStreamLobby,
      homePageGames,
      homePageProfiles,
      phoneNumberSettings: persistReducer(
        makePersistConfig(
          "phoneNumberSettings",
          phoneNumberSettingsPersistConfig
        ),
        phoneNumberSettings
      ),
      welcomeOnboardingOffer,
      appeal,
      mosV2,
      bonusWheel,
      homePageV2Nearby,
      homePageStreams,
      homePageCreators,
      pipsConfig,
      broadcastExternal: persistReducer(
        makePersistConfig("broadcastExternal", broadcastExternalPersistConfig),
        broadcastExternalReducer
      ),
      tutorials: persistReducer(
        makePersistConfig("tutorials", tutorialsPersistConfig),
        tutorialsReducer
      ),
    }),
    state,
    composeEnhancers(applyMiddleware(...middleware))
  );

const configureStore = () => {
  clearPersistLandingPageKey();
  const store = createAppStore();
  addAdditionalUnauthorizedErrorHandler(createUnauthorizedErrorHandler(store));
  addCaptchaHandler(createCaptchaHandler(store));
  addSessionTokenRefreshHandler(createSessionTokenRefreshHandler(store));
  injectJoshGoBack(store);
  const { afterStoreCreatedHook } = getHooks();
  afterStoreCreatedHook && afterStoreCreatedHook(store);

  return { store, persistor: persistStore(store) };
};

export default configureStore;
