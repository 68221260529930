import { createReducer } from "@reduxjs/toolkit";
import { loadCountriesForSignIn } from "state/actionCreators/countries";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  initialFetcherStateMeta,
} from "state/hor/addFetcherActionsToBuilder";

export const persistConfig = {
  whitelist: ["data"],
};

export type Country = {
  countryCode: string;
  countryIcon: string;
  countryId: string;
  countryName: string;
  isoCountryCode: string;
};

export type CountriesState = AsyncState<Country[], string>;

export const initialCountriesState = {
  data: [],
  meta: initialFetcherStateMeta,
};

export default createReducer<CountriesState>(
  initialCountriesState,
  (builder) => {
    addAsyncCasesToBuilderV2({
      initialData: initialCountriesState.data,
      builder,
      action: loadCountriesForSignIn,
      prepareData: (_, newData) => newData,
    });
  }
);
