import {
  LiveStreamLocationState,
  ViralityLinkLocationState,
} from "@analytics/livePlay/streamLocationState";
import { TANGO_ACCOUNT_ID } from "src/constants";
import {
  CombinedFeedType,
  LiveFeedType,
  PersonalFeedType,
  TimeFrame,
} from "src/enums";
import { Nullable } from "src/types/common";
import { BasicProfile } from "src/types/profile/profile";

const checkNonEmptyString = (
  value: Nullable<string | undefined>
): value is string => !!value?.trim();

export const URL_TO_RESOURCES_CDN = "https://resources.tango.me";
export const OBS_WEBSITE_LINK = "https://obsproject.com/download";
export const linkToBasePath = "/";
export const linkToWelcome = "/welcome";
export const linkToRecommended = "/live/recommended";
export const linkToFollowingTab = "/following";
export const linkToFeed = "/feed";
export const linkToLegacyFeed = "/timeline";
export const linkToLoyalty = "/loyalty";
export const linkToArtistsClub = "/artists";
export const linkToCreators = "/creators";
const baseLinkToLeaders = "/leaders";
export const linkToLeaders = `${baseLinkToLeaders}/:mode?`;
export const linkToUserProfileOrLiveStream = "/:nickname";
export const makeLinkToLeadersWithTimeframe = (timeframe = TimeFrame.DAILY) =>
  `${baseLinkToLeaders}/${timeframe}`;
export const defaultLinkToLeaders = makeLinkToLeadersWithTimeframe(
  TimeFrame.DAILY
);
export const linkToChat = "/chat";
export const linkToChatMatch = `${linkToChat}/:id?`;

export const linkToRequest = `requests`;
export const linkToMessageRequest = `${linkToChat}/${linkToRequest}`;
export const linkToMessageRequestMatch = `${linkToMessageRequest}/:id?`;

export const AUTH_REQUIRED_LINKS = [linkToLoyalty, linkToArtistsClub];

export const makeLinkToChat = (conversationId: string, source: number) => ({
  pathname: `${linkToChat}${
    checkNonEmptyString(conversationId) && `/${conversationId}`
  }`,
  state: { source },
});

export const makeLinkToMessageRequest = (
  conversationId: string,
  source: number
) => ({
  pathname: `${linkToMessageRequest}${
    checkNonEmptyString(conversationId) && `/${conversationId}`
  }`,
  state: { source },
});
export const linkToLiveStream = "/live";
export const linkToLiveStreamMatch = `${linkToLiveStream}/:mode?`;

const generateProfileLink = (accountId: string) => `/profile/${accountId}`;

export const linkToTangoProfile = generateProfileLink(TANGO_ACCOUNT_ID);

export const linkToCommonLiveStreams = `${linkToLiveStream}/:mode`;
export const makeLinkToProfile = (
  accountId: Nullable<string | undefined>,
  basicProfile?: BasicProfile | undefined
) => {
  const isAccountIdValid = checkNonEmptyString(accountId);

  if (!isAccountIdValid) {
    return linkToLiveStream;
  }

  const alias = basicProfile?.aliases?.[0].alias;

  return {
    pathname: checkNonEmptyString(alias)
      ? `/${alias}`
      : generateProfileLink(accountId),
    state: {
      accountId,
    },
  };
};
export const linkToStreamBase = "/stream";
export const linkToStreamMatch = "/stream/:id";
export const linkToSearchBase = "/search/";
export const linkToSearchMatch = "/search/:searchQuery?";

export const makeLinkToLiveStream = (
  streamId: Nullable<string | undefined>,
  locationState?: LiveStreamLocationState | ViralityLinkLocationState
) => ({
  pathname: checkNonEmptyString(streamId)
    ? `${linkToStreamBase}/${streamId}`
    : linkToLiveStream,
  state: locationState,
});

export const makeLinkToLiveStreamsFeed = (feedType: CombinedFeedType) => {
  if (!checkNonEmptyString(feedType)) {
    return linkToLiveStream;
  }

  return feedType === PersonalFeedType.FOLLOWING
    ? linkToFollowingTab
    : `${linkToLiveStream}/${feedType}`;
};

export const linkToLiveStreamNearbyTab = makeLinkToLiveStreamsFeed(
  LiveFeedType.NEARBY
);
export const linkToLiveStreamHottestTab = makeLinkToLiveStreamsFeed(
  LiveFeedType.POPULAR
);
export const linkToLiveStreamLatestTab = makeLinkToLiveStreamsFeed(
  LiveFeedType.NEW
);
export const linkToCreatorsTab = makeLinkToLiveStreamsFeed(
  LiveFeedType.CREATORS
);
export const linkToRecommendationsTab = makeLinkToLiveStreamsFeed(
  LiveFeedType.RECOMMENDED
);

export const defaultRoute = linkToLiveStream;

export const linkToTermsOfUse = "/terms-of-use";
export const linkToPrivacyPolicy = "/privacy-policy";
export const linkToCopyright = "/copyright";
export const linkToThirdPartyLicense = "/third-party-license";
export const linkToBroadcasterAgreement = "/broadcaster-agreement";
export const linkToCommunityGuidelines = "/community-guidelines";
export const linkToPublisherTermsAndConditions = "/publisherTermsAndConditions";
export const linkToFunctionIsNotAvailable = "/functionIsNotAvailable";
export const linkToBugBountyProgram = "/bug-bounty-program";
export const linkToDSAInformation = "/dsa-information";
export const linkToFAQ = "/faq";

export const deepLink = "/deeplink/q";
export const linkToSubscriptions = "/subscriptions";
export const linkToSubscribers = "/subscribers";
export const linkToSettingsBase = "/settings";
export const linkToProfileBase = "/profile";
export const linkToProfileMatch = `${linkToProfileBase}/:accountId`;
export const linkToSettingsMatch = `${linkToSettingsBase}/:type?`;
export const makeLinkToSettingsScreen = (type: string) =>
  `${linkToSettingsBase}/${type}`;

export const linkToHappyMoments = "/moments";
export const linkToHappyMomentsMatch = `${linkToHappyMoments}/:id`;
export const makeLinkToHappyMoment = (id: string) =>
  `${linkToHappyMoments}/${id}`;
export const linkToBroadcast = "/broadcast";

export const linkToWebviewSafechargeOffers = "/safechargeOffers";
export const linkToWebviewApmOffers = "/apmOffers";
export const linkToWebviewSinglePaymentMethod = "/paymentMethod";
export const linkToWebviewCheckout = "/paymentWebview";

export const linkToGiftCards = "/voucher";

export const linkToLandingPagePhoenix = "/landingPage";

export const linkToOnboardingLandingPage = "/TangoTips";

export const linkToHowToTango = "/how-to-tango";

export const linkToNFTAuction = "/auction";

export const makeLinkToUserSearch = (query: string) =>
  `${linkToSearchBase}${query}`;

export const TOURNAMENT_PATH = "/tournaments";
export const LEADERBOARD_PATH = "/leaderboards";
export const linkToTournamentMatch = `${TOURNAMENT_PATH}/:name`;
export const linkToLeaderBoard = `${LEADERBOARD_PATH}/:name/:tabName?`;
export const linkToReferralProgram = "/referral-program";

export const linkToSocialGamesBase = "/social-games";

export const linkToSocialGamesGameMatch = `${linkToSocialGamesBase}/:gameId`;

export const linkToSocialGamesCategoryMatch = `${linkToSocialGamesBase}/category`;

export const linkToSocialGamesSearchMatch = `${linkToSocialGamesBase}/search`;

export const linkToSocialGamesDeposit = `${linkToSocialGamesBase}/deposit`;

export const makeLinkToSocialGames = (targetSocialGameId: Nullable<string>) => {
  if (targetSocialGameId !== null) {
    return `${linkToSocialGamesBase}/${targetSocialGameId}`;
  }

  return linkToSocialGamesBase;
};

export const linkToProfileSettingsPage = "/settings";

export const linkToGetTheApp = "/getapp";
export const linkToRegistration = "/registration";
export const linkToRegpage1 = "/regpage1";

// contains links to restore previous user location
// when is redirected to root path '/'
export const restoreNavigationWhitelist = [
  linkToFollowingTab,
  linkToFeed,
  baseLinkToLeaders,
  linkToChat,
  linkToSearchBase,
  linkToSettingsBase,
  linkToBroadcast,
  linkToOnboardingLandingPage,
];

export const linkToAppealPage = "/webappeal";

export const linkToHomePageV3 = "/worldwide";

export const linkToHomeV2Page = "/discover";
